import axios from "@/apiUrl/axios.js";
import router from "../router";
import store1 from '../store'

// 本地
// export const apiBaseUrl = "http://192.168.1.248:8080/"
// export const sourc = 'https://wap.lezhien.com/'
// export const sourc2 = 'https://resource.lezhien.com/'
// 线上
// export const apiBaseUrl = "https://jishiapi.minglisoft.cn/"
// export const sourc = 'https://jishiwap.minglisoft.cn/'
// export const sourc2 = 'https://jishires.minglisoft.cn/'

// export const apiBaseUrl = "https://portalapi.1x1t.com/"
// export const sourc2 = 'https://portalres.1x1t.com/'

export const apiBaseUrl = "http://portalapi.hsz88.com/"
export const sourc2 = 'http://portalres.hsz88.com/'

//上传图片
export const upload_url = `${apiBaseUrl}api-honghu-file/upload/v1`
export const uploadVideo = `${apiBaseUrl}api-honghu-file/ftp/uploadVideo`

function getData(url,data,obj,type = ''){
    return new Promise((resolve,reject)=>{
        type == 'arraybuffer' ? type = 'arraybuffer' : type =  ''
        
        let options = {
            method:obj.method,
            url,
            loading:obj.loading,
            responseType: type
        }
        if (obj.method === 'get') {
            options.params = data
        }else if(obj.method === 'delete' || obj.method === 'get2') {
            options.url = options.url + '/' + data
        }else {
            options.data = data
        }
        if(obj.method == 'get2') {
            options.method = 'get'
        } 
        if(obj.method == 'delete2') {
         options.method = 'delete'
      } 
        axios(options).then(res=>{
            if(res.code==400 || res.code==401){
                resolve(res)
                return
            }else if(res.data.code==507 ||res.data.code==508 ||res.data.code==509){
                store1.commit('settoken', '')
                store1.commit('setuserInfo', {})
                // if(router.currentRoute.path != '/login') {
                //     router.push({path:'/login',query: { redirect: router.currentRoute.fullPath }})
                // }
                return
            }
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}
function getBanner(){
    return new Promise((resove,reject)=>{
            resove({
                url:[
                    baseURL+"/images/a.jpg",
                    baseURL+"/images/b.jpg",
                    baseURL+"/images/c.jpg",
                    baseURL+"/images/d.jpg",
                    baseURL+"/images/e.jpg",
                ]
            })
    })
}









// export function login2(data){// 登录 
//    return getData(`${apiBaseUrl}api-honghu-member/member/login2`,data,{loading:true,method:'post'})
// }
export function login2(data){// 登录 
   return getData(`${apiBaseUrl}api-honghu-member/member/login`,data,{loading:true,method:'post'})
}
export function register(data){// 注册 
   return getData(`${apiBaseUrl}api-honghu-member/member/register`,data,{loading:true,method:'post'})
}
export function reset(data){// 忘记密码 
   return getData(`${apiBaseUrl}api-honghu-member/member/password/reset`,data,{loading:true,method:'post'})
}
export function modifyPassword(data){// 修改密码 
   return getData(`${apiBaseUrl}api-honghu-member/member/modifyPassword`,data,{loading:true,method:'post'})
}
export function getUser(data){// 获取个人信息 
   return getData(`${apiBaseUrl}api-honghu-member/member/getUser?id=${data.id}`,{},{loading:false,method:'get'})
}
export function changePhoto2(data){// 修改头像 
   return getData(`${apiBaseUrl}api-honghu-member/member/changePhoto2`,data,{loading:true,method:'post'})
}
export function updateMemberBase(data){// 修改资料
   return getData(`${apiBaseUrl}api-honghu-member/member/updateMemberBase`,data,{loading:true,method:'post'})
}
export function selectByMark(data){// 协议 
   return getData(`${apiBaseUrl}api-honghu-operate/tenantProtocol/selectByMark`,data,{loading:true,method:'post'})
}
export function sendCode(data){// 获取验证码 
   return getData(`${apiBaseUrl}api-honghu-member/mobileCode/sendCode`,data,{loading:true,method:'post'})
}
export function getByTenantId(data){// 租户配置 
   return getData(`${apiBaseUrl}api-honghu-operate/tenantSet/getByTenantId`,data,{loading:false,method:'get'})
}
export function typeMessages(data){// 用户消息 
   return getData(`${apiBaseUrl}api-honghu-message/sysMessage/typeMessages`,data,{loading:true,method:'post'})
}
export function advList(data){// 轮播图
   return getData(`${apiBaseUrl}api-honghu-home/index/advList`,data,{loading:false,method:'get'})
}
export function dictList(data){// 数据字典
    return getData(`${apiBaseUrl}api-honghu-common/sysDict/dictList`,data,{loading:false,method:'get'})
}
export function loadList(data){// 行业
    return getData(`${apiBaseUrl}api-honghu-operate/tenantClassify/loadList`,data,{loading:false,method:'get'})
}
export function load_area(data){// 获取地址
    return getData(`${apiBaseUrl}api-honghu-common/region/load_area`,data,{loading:false,method:'get'})
}
export function homeIndex(data){// 首页
    return getData(`${apiBaseUrl}api-honghu-home/commonHome/index`,data,{loading:false,method:'post'})
}
export function jsonIndex(data){// 首页
    return getData(`${apiBaseUrl}api-honghu-home/commonHome/jsonIndex`,data,{loading:false,method:'post'})
}
export function pageVideo(data){// 视频查询
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/pageVideo`,data,{loading:false,method:'post'})
}
export function selectByPid(data){// 查询下级分类
    return getData(`${apiBaseUrl}api-honghu-operate/jobClassify/selectByPid`,data,{loading:false,method:'get'})
}
export function downloadFile(data){// 下载文件
    return getData(`${apiBaseUrl}api-honghu-file/download`,data,{loading:false,method:'post'})
}
export function selectByIdBase(data){// 将6位数转成32位id
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/selectByIdBase`,data,{loading:false,method:'get'})
}
export function videoCommentsDel(data){// 删除评论
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/del`,data,{loading:false,method:'get'})
}
export function addDownLoadNum(data){// 增加报告下载量
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoReport/addDownLoadNum`,data,{loading:false,method:'post'})
}
export function addDownLoadNum1(data){// 增加报告下载量
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoReport/addDownLoadNum1`,data,{loading:false,method:'post'})
}
export function videoDetails(data){// 视频详情
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/videoDetails`,data,{loading:false,method:'post'})
}
export function praise(data){//视频点赞
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/praise`,data,{loading:false,method:'post'})
}
export function addVideoCollection(data){//视频收藏
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoCollection/addVideoCollection`,data,{loading:false,method:'post'})
}
export function DylistComments(data){//动态评论
    return getData(`${apiBaseUrl}api-honghu-member/commentDynamic/listComments`,data,{loading:false,method:'post'})
}
export function listComments(data){//视频评价列表
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/listComments`,data,{loading:false,method:'post'})
}
export function selectMidRecord(data){//获取视频观看记录
    return getData(`${apiBaseUrl}api-honghu-member/videoWatchRecord/selectMidRecord`,data,{loading:false,method:'post'})
}
export function addMidRecord(data){//添加观看记录
    return getData(`${apiBaseUrl}api-honghu-member/videoWatchRecord/add`,data,{loading:false,method:'post'})
}
export function payOpenMember(data){//开通vip
    return getData(`${apiBaseUrl}api-honghu-pay/orderFormPay/payOpenMember`,data,{loading:false,method:'post'})
}
export function getVIPPrice(data){//获取开通vip价格
    return getData(`${apiBaseUrl}api-honghu-member/appointment/getVIPPrice`,data,{loading:false,method:'post'})
}
export function payOpen(data){//购买课程
    return getData(`${apiBaseUrl}api-honghu-pay/orderFormPay/payCourse`,data,{loading:false,method:'post'})
}
export function orderSettingGet(data){//名师/vip权益
    return getData(`${apiBaseUrl}api-honghu-member/orderSetting/get`,data,{loading:false,method:'post'})
}
export function listParentComments(data){//视频评价详情列表
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/listParentComments`,data,{loading:false,method:'post'})
}
export function comment(data){//视频评价回复
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/comment`,data,{loading:false,method:'post'})
}
export function courseOpenTime(data){//记录播放
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/courseOpenTime`,data,{loading:false,method:'post'})
}
export function selectWatchList(data){//观看课程
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/selectWatchList`,data,{loading:false,method:'post'})
}
export function payCourseList(data){//购买课程
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/payCourseList`,data,{loading:false,method:'post'})
}
export function userCollectionList(data){//用户收藏列表
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoCollection/userCollectionList`,data,{loading:false,method:'post'})
}
export function selectByIdBase2(data){//跳转
    return getData(`${apiBaseUrl}api-honghu-knowledge/video/selectByIdBase`,data,{loading:false,method:'get'})
}
export function myVideoList(data){// 视频/报告评论
    return getData(`${apiBaseUrl}api-honghu-knowledge/videoComments/myVideoList`,data,{loading:false,method:'post'})
}
export function selectRootClcass(data){// 查询一级分类
    return getData(`${apiBaseUrl}api-honghu-operate/jobClassify/selectRootClcass`,data,{loading:false,method:'get'})
}
export function dynamicDetails(data){// 动态详情
    return getData(`${apiBaseUrl}api-honghu-member/dynamic/dynamicDetails`,data,{loading:false,method:'post'})
}
export function addDynamicPraise(data){// 动态点赞
    return getData(`${apiBaseUrl}api-honghu-member/dynamicPraise/addDynamicPraise`,data,{loading:false,method:'post'})
}
export function dongtai_comment(data){// 回复评价
    return getData(`${apiBaseUrl}api-honghu-member/commentDynamic/comment`,data,{loading:false,method:'post'})
}
export function cdelComments(data){// 删除评论
    return getData(`${apiBaseUrl}api-honghu-member/commentDynamic/delComments`,data,{loading:false,method:'post'})
}
export function pageCompanyList(data){// 公司资讯分页查询
    return getData(`${apiBaseUrl}api-honghu-member/dynamic/pageCompanyList`,data,{loading:false,method:'post'})
}
export function selectBySingle(data){// 根据应用标识查询二级分类
    return getData(`${apiBaseUrl}api-honghu-operate/jobClassify/selectBySingle`,data,{loading:false,method:'post'})
}
export function getByIdBase(data){// 资讯详情(简易版)
    return getData(`${apiBaseUrl}api-honghu-member/dynamic/getByIdBase`,data,{loading:false,method:'get'})
}
export function aboutInfo(data){// 查询租户的关于我们	
    return getData(`${apiBaseUrl}api-honghu-operate/about/info`,data,{loading:false,method:'get'})
}
export function teacherPage(data){// 导师列表	
    return getData(`${apiBaseUrl}api-honghu-member/member/nonLogin/teacherPage`,data,{loading:false,method:'post'})
}
export function userDetails(data){// 导师详情	
    return getData(`${apiBaseUrl}api-honghu-member/member/nonLogin/userDetails`,data,{loading:false,method:'post'})
}
export function listTeacherWork(data){// 导师经历列表
    return getData(`${apiBaseUrl}api-honghu-member/teacherWork/nonLogin/listTeacherWork`,data,{loading:false,method:'post'})
}
export function relateConsultInsert(data){// 相关咨询提交
    return getData(`${apiBaseUrl}api-honghu-operate/relateConsult/nonLogin/insert`,data,{loading:false,method:'post'})
}
