<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="mentor-detail">
                <div class="mentor-info">
                    <div class="mentor-info__avatar">
                        <img :src="obj.headPortraitUrl" alt="">
                    </div>
                    <div class="mentor-info__main">
                        <div class="mentor-info__info">
                            <div class="mentor-info__name">{{ obj.name }}</div>
                            <div class="mentor-info__city">
                                <i class="iconfont icon-chufadihedidian"></i>
                                {{ obj.member.cityName }}
                            </div>
                        </div>
                        <div class="mentor-info__title">{{ obj.member.company }}</div>
                    </div>
                </div>
                <!-- 职位头衔 -->
                <div class="mentor-intro">
                    <h3 class="section-title">职位头衔</h3>
                    <p class="section-content">
                        {{ obj.member.jobTitle }}
                    </p>
                </div>
                <!-- 职位头衔 -->
                <div class="mentor-intro">
                    <h3 class="section-title">职业经历</h3>
                    <p class="section-content">
                        <el-table
                        :data="list"
                        border
                        :cell-style="{'fontWeight':700,'fontSize':'18px'}"
                        style="width: 100%;"> 
                        <el-table-column prop="company" label="公司名称" width="180"></el-table-column>
                        <el-table-column prop="position" label="职位" width="180"></el-table-column>
                        <el-table-column label="在职时间" width="280">
                            <template slot-scope="scope">
                                {{ scope.row.startTime }} - {{ scope.row.endTime }} 
                            </template>
                        </el-table-column>
                        <el-table-column label="是否在职" width="159">
                            <template slot-scope="scope">
                                {{ scope.row.workStatus == 1 ? '在职' : '离职' }}
                            </template>
                        </el-table-column>
                    </el-table>
                    </p>
                </div>
                <!-- 专业成就 -->
                <div class="mentor-intro">
                    <h3 class="section-title">专业成就</h3>
                    <p class="section-content">
                        {{ obj.majorAchievement }}
                    </p>
                </div>
            </div>
        </div>
        <gap :height="100"></gap>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import { userDetails,listTeacherWork } from "@/apiUrl/index.js";
    export default {
        data() {
            return {
                id:'',
                obj:{member:{}},
                list:[],
                majorAchievementPhotoList:[]
            }
        },
        created() {
            let {id} = this.$route.query;
            this.id = id;
            this.getData()
        },
        methods:{
            getData() {
                userDetails({id:this.id,form:'form'}).then(res => {
                    if(res.code == 200) {
                        this.obj = res.data
                        if(this.obj.majorAchievementPhoto) {
                            this.majorAchievementPhotoList = this.obj.majorAchievementPhoto.split(',')
                            console.log('majorAchievementPhotoList',this.majorAchievementPhotoList)
                        }
                        
                    }
                })
                listTeacherWork({mid:this.id,form:'form'}).then(res => {
                    if(res.code == 200) {
                        this.list = res.data
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
.main {
    position: relative;
    // width: 100%;
    width: 1068px;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* 导师信息 */
.mentor-detail {
    .mentor-info {
        display: flex;
        align-items: flex-start;
        // align-items: center;
        
        margin-bottom: 60px;
        width: 800px;
        .mentor-info__avatar {
            width: 200px;
            height: 200px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.mentor-info__main {
    flex: 1;
    padding-left: 20px;
}
.mentor-info__city, .mentor-info__info {
    display: flex;
}
.mentor-info__info {
//    justify-content: space-between;
}
.mentor-info__name {
    line-height: 36px;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1.2px;
    color: #222;
    max-width: 360px;
}
.mentor-info__city {
    font-size: 14px;
    flex: none;
    align-items: flex-start;
    margin: 10px 10px 0 12px;
    line-height: 16px;
    color: #c4c4c4;
}
.mentor-detail .mentor-info .share-btn {
    margin-top: 4px;
    margin-left: auto;
    font-size: 28px;
    color: #979797;
}
.mentor-info__title {
    margin-top: 4px;
    margin-bottom: 20px;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: .6px;
    color: #222;
}
.mentor-info__item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: .4px;
}
.mentor-info__item .name {
    display: inline-flex;
    align-items: center;
    width: 72px;
    color: #333;
}
.mentor-info__item .mentor-info_content {
    margin-left: 10px;
    color: #b37d74;
}
/* tab栏 */
.nav-list {
    // position: relative;
    position: sticky;
    display: flex;
    align-items: stretch;
    height: 60px;
    background: #fff;
    width: 628px;
    // top: 60px;
    top: 0;
    margin-top: 0px;
    transition: top .3s;
    z-index: 100;
}
.nav-item {
    
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    font-size: 14px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    color: #333;
}
.mentor-detail .nav-list .nav-item.is-active {
    color: #333;
    border-bottom: 4px solid #f85f48;
}
/* 话题详情 */
.topic-list {
    width: 628px;
    margin-top: 30px;
    margin-bottom: 80px;
}
.topic-list .topic-item {
    margin-bottom: 29px;
}
.topic-item {
    .topic-item__title {
        border-left: 4px solid #c4c4c4;
        height: 48px;
        line-height: 48px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #222;
        background: #ececec;
    }
    .topic-item__main {
        position: relative;
        padding: 12px 24px;
        background: #fff;
    }
    .topic-item__content {
        position: relative;
        line-height: 22px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333;
        white-space: pre-line;
        word-break: break-all;
        max-height: 112px;
        overflow: hidden;
    }
    .topic-item__content.is-expanded {
        max-height: none;
    }
    .btn-fold {
        display: block;
        position: absolute;
        left: 24px;
        bottom: 12px;
        width: 48px;
        line-height: 37px;
        font-size: 14px;
        letter-spacing: .4px;
        text-align: left;
        color: #8996ca;
        cursor: pointer;
    }
    .topic-item__price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 12px;
        height: 37px;
    }
    .topic-item__price .price, .topic-item__price .unit {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        letter-spacing: 1px;
        color: #f85f48;
        font-family: DIN,sans-serif;
    }
}
/* 专业成就 */
.mentor-intro {
    width: 800px;
    margin-top: 40px;
}
.section-title {
    font-size: 24px;
    letter-spacing: 1.4px;
}
.section-content {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
    color: #333;
    white-space: pre-line;
    word-break: break-all;
    text-align: justify;
}
.mentor-intro .section-content {
    margin-top: 24px;
}
/* 学员评价 */
.comments {
    width: 628px;
    margin-top: 80px;
}
.section-title {
    font-size: 24px;
    letter-spacing: 1.4px;
    position: relative;
    padding-left: 15px;
}
.section-title, .small-title {
    line-height: 32px;
    font-weight: 600;
    color: #222;
}
.comments .comment-list {
    margin-top: 24px;
}
.comment-item__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.comment-item__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.comment-item__name {
    flex: 1 0 auto;
    margin-left: 14px;
    height: 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    letter-spacing: 1px;
    color: #222;
}
.comment-item__date {
    margin-left: auto;
    height: 18px;
    font-size: 12px;
    line-height: 1.29;
    letter-spacing: .4px;
    color: #c4c4c4;
}
.comment-item__content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
    color: #333;
}
.comment-item__topic {
    margin-top: 20px;
    line-height: 18px;
    font-size: 14px;
    letter-spacing: .4px;
    color: #c4c4c4;
}
/* 选择约聊方式 */
.consult-wrapper {
    position: fixed;
    top: 174px;
    bottom: auto;
    right: 50%;
    margin-right: -534px;
    z-index: 100;
    font-size: 14px;
    box-sizing: border-box;
    width: 380px;
    padding: 20px 30px;
    background-color: #fff;
    .title {
        color: #999;
        letter-spacing: .44px;
        display: flex;
        justify-content: space-between;
    }
    .topic-container {
        margin-top: 8px;
        max-height: 260px;
        overflow: auto;
    }
    .topic-items {
        cursor: pointer;
        height: 40px;
        border: 1px solid #ececec;
        margin-bottom: 8px;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 6px;
        letter-spacing: .78px;
    }
    .topic-items.active {
        color: #f85f48;
        border: 1px solid #f85f48;
    }
    .consult-types {
        margin-top: 16px;
    }
    .type-container {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        flex-wrap: wrap;
    }
    .consult-type-item {
        cursor: pointer;
        width: 150px;
        margin-bottom: 20px;
        position: relative;
    }
    .item-main {
        background-color: #fff;
        padding: 6px;
        box-sizing: border-box;
        border: 1px solid #ececec;
    }
    .item-main.inactive {
        background-color: #f6f6f6;
        border: 1px dashed #ececec;
        color: #999;
    }
    .consult-type-item .item-main .item_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #333;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        span {
            font-size: 12px;
        }
    }
    .item-main.inactive .item_title {
        color: #999;
    }
    .item-main .des {
        font-size: 12px;
        line-height: 18px;
        color: #999;
        letter-spacing: 0;
    }
    .item-main .price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        line-height: 21px;
    }
    .item-main .price-container .disabled {
        font-weight: 600;
        font-size: 14px;
        color: #999;
        letter-spacing: .44px;
    }
    .item-main .price-container .price-wrapper {
        font-family: DIN,sans-serif;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        display: flex;
    }
    .item-main .price-container .actual-price {
        color: #333;
        margin-right: 4px;
    }
    .consult-type-item .item-main {
        background-color: #fff;
        padding: 6px;
        box-sizing: border-box;
        border: 1px solid #ececec;
    }
    .item-main.active {
        border: 1px solid #f85f48;
    }
    .picker-footer {
        display: flex;
        justify-content: space-between;
        height: 50px;
    }
    .picker-footer .confirm-btn {
        width: 270px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #999;
        font-size: 16px;
        letter-spacing: .88px;
        color: #fff;
        cursor: pointer;
    }
    .picker-footer .confirm-btn.active {
        background-color: #f85f48;
    }
    .picker-footer .btn-kefu {
        width: 34px;
        height: 50px;
        font-size: 12px;
        letter-spacing: .4px;
        color: #999;
    }
}
button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
}
.section-title::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    width: 5px;
    height: 25px;
    background-color: var(--color);
    border: 4px;
    position: absolute;
    top: 50%;
    left:0;
    transform: translateY(-50%);
}
</style>