import { render, staticRenderFns } from "./publicDetail.vue?vue&type=template&id=e5ed2638&scoped=true&"
import script from "./publicDetail.vue?vue&type=script&lang=js&"
export * from "./publicDetail.vue?vue&type=script&lang=js&"
import style0 from "./publicDetail.vue?vue&type=style&index=0&id=e5ed2638&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5ed2638",
  null
  
)

export default component.exports