<template>
    <div>
        <Header></Header>
        <banner img_url="http://47.121.134.73:8001/profile/upload/2023/05/10/72037484f6dc41a89f6791356ddb88ea.jpg"></banner>
        <headline title="相关咨询"></headline>
        <div class="content">
            <div class="consult-box">
                <div class="consult-list">
                    <div class="title">
                        咨询问题：
                    </div>
                    <div class="input">
                        <el-input type="textarea" v-model="consultProblem" placeholder="请输入咨询问题" max-length="500"></el-input>
                    </div>
                </div>
                <div class="consult-list">
                    <div class="title">
                        单位机构：
                    </div>
                    <div class="input">
                        <el-input placeholder="请输入单位机构名称" v-model="officeName"  max-length="200"></el-input>
                    </div>
                </div>
                <div class="consult-list">
                    <div class="title">
                        联系电话：
                    </div>
                    <div class="input">
                        <el-input placeholder="请输入联系电话" v-model="contactPhone"  max-length="50"></el-input>
                    </div>
                </div>
                <div class="consult-list">
                    <div class="title">
                        电子邮箱：
                    </div>
                    <div class="input">
                        <el-input placeholder="请输入电子邮箱" v-model="email"  max-length="200"></el-input>
                    </div>
                </div>
                <div class="consult-list spjz">
                    <div class="title">
                        上传图片：
                    </div>
                    <div class="input avatar-uploader">
                        <el-upload
                            :action="upload_url"
                            :multiple="false"
                            :headers="{token:token,tenantId:$store.state.tenantId}"
                            :data="{folder:'user/image'}"
                            :show-file-list="false"
                            :on-success="onsuccess"
                            :on-error="$on_error"
                            :before-upload="$before"
                            :limit="999">
                                <img v-if="legalIdFace" :src="legalIdFace" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </div>
                </div>
                <div class="submit-btn" @click="submit_form">
                    提交申请
                </div>
            </div>
        </div>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
import { sourc, sourc2,upload_url,relateConsultInsert } from "@/apiUrl/index.js";
import { mapGetters } from "vuex";
import { phoneValidator ,emailValidate} from '@/utils/validator.js'
export default {
    computed: {
        ...mapGetters(["userInfo", "config", "login_show", "token"]),
    },
    data() {
        return {
            sourc,
            sourc2,
            upload_url,
            consultProblem:'',//咨询问题
            officeName:'',//单位机构名称
            contactPhone:'',//联系电话
            email:'',//电子邮箱
            legalIdFace:'',//图片
        };
    },
    created() {

    },
    methods: {
        onsuccess(res){
            if(res.code==200){
                this.legalIdFace = res.data.url0;
            }else{
                this.$msg(res.message,3);
            }
        },
        submit_form(){
            if(this.consultProblem==''){
                this.$msg('请输入咨询问题')
                return
            }
            if(this.officeName==''){
                this.$msg('请输入单位机构名称')
                return
            }
            if(this.contactPhone==''){
                this.$msg('请输入联系电话')
                return
            }
            if(this.email==''){
                this.$msg('请输入电子邮箱')
                return
            }
            if(phoneValidator(this.contactPhone)) { // 验证手机号格式
                return false
            }
            if(emailValidate(this.email)) { // 验证邮箱格式
                return false
            }
            relateConsultInsert({consultProblem:this.consultProblem,officeName: this.officeName,contactPhone:this.contactPhone,email:this.email,legalIdFace:this.legalIdFace}).then(res => {
                if(res.code == 200) {
                    this.$msg('提交成功！')
                    this.consultProblem='';
                    this.officeName = '';
                    this.contactPhone = '';
                    this.email = '';
                    this.legalIdFace = '';
                    // this.$link('/')

                } else {
                    this.$msg(res.message,3);
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
    .consult-box {
        width: 600px;
        margin: 20px auto;
    }
    .consult-list {
        display: flex;
        margin-top: 40px;
    }
    .consult-list .title {
        font-size: 24px;
        color: #202020;
        width: 120px;
    }
    .consult-list .input {
        flex: 1;
        font-size: 28px;
        margin-top: -5px;
    }
    .avatar-uploader /deep/ .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader /deep/ .el-upload:hover {
        border-color: var(--color);
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .submit-btn {
        color: var(--color);
        width: 180px;
        height: 50px;
        border-radius: 8px;
        text-align: center;
        line-height: 46px;
        border: 1px solid var(--color);
        margin-left: 120px;
        margin-top: 20px;
        font-size: 28px;
        cursor: pointer;
    }
    // /deep/ .footer2{
    //     background-color: #fff;
    // }
    // /deep/ .footer2 span{
    //     color: #3a3a3a;
    // }
</style>
