import Vuex from 'vuex'
import Vue from 'vue'

import getters from '@/store/getters.js'

import user from '@/store/modules/user.js'
import {
  setlocal,
  getlocal,
} from '@/utils/stroage.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {																																		
		user,
	},
	getters,
	state:{
		access_token:getlocal('access_token')||'',
		// 全局固定配置
		tenantId:'58442d36b05e4b4e9c79400dd39be07f', // 好实在	
		// tenantId:'3cfb027eb4634dfab764403a4cfdf01a', // 本地mingli

	},
	mutations:{
		setaccess_token(state, val) {
            state.access_token = val;
            setlocal("access_token", val);
        },
	}
})

export default store
