<template>
    <div>
        <div class="list">
            <div class="item" v-for="item in list" @click="link_detail(item.id)">
                <div class="item_img">
                    <img :src="item.faceUrl" alt="">
                </div>
                <div class="item_content">
                    <div class="item_title">{{ item.content }}</div>
                    <div class="color666">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                </div>
                <div class="item_detail">
                    <div>查看详情</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        props:{
            list:{
                type:Array,
                default:() => []
            }
        },
        methods:{
            link_detail(id) {
                this.$links('/publicDetail?id=' + id)
            }
        }
    }
</script>

<style lang="less" scoped>
.list {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.item {
    padding: 22px 36px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    cursor: pointer;

}
.item:hover {
    box-shadow: 0 0 6px 1px rgb(109 179 63 / 27%);
}
.item_img img {
    width: 260px;
    height: 170px;
    margin-right: 10px;
}
.item_content {
    margin-left: 10px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item_title {
    line-height: 60px;
    font-size: 18px;
    color: #333;
    font-weight: 700;
}
.item_detail {
    display: flex;
    flex:1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: end;
}
.item_detail div {
    width: 104px;
    padding: 8px 0;
    text-align: center;
    border: 1px solid #ccc;
    color: #666;
    font-size: 12px;
    border-radius: 50px;
    cursor: pointer;
}
</style>