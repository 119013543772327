<template>
    <div>
        <Header></Header>
        <div class="content">
            <headline :title="'搜索列表'"></headline>
            <!-- <div class="mt20">
                <tabList :list="tabList" @click="isnum"></tabList>
            </div> -->
            <div class="mt50"></div>
            <dynamicList :list="list"></dynamicList>
            <nodata v-if="list.length == 0"></nodata>
            <div class="spczjz mt20 mb20">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="12"
                layout="prev, pager, next"
                :total="count">
                </el-pagination>
            </div>
        </div>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import {  pageCompanyList} from "@/apiUrl/index.js";
    import dynamicList from '@/components/dynamicList.vue';
    import tabList from '@/components/tabList.vue';
    export default {
        components:{
            dynamicList,
            tabList
        },
        data() {
            return {
                pid:'',
                name:'',
                list:[],
                currentPage:1,
                count:0,
                val:'',
                tabList:[{name:'要闻快讯',id:'1'},{name:'最新热点',id:'2'},{name:'行业资讯',id:'3'},{name:'会议活动',id:'4'}]
            }
        },
        created() {
            let {val} = this.$route.query;
            this.val = val;
            this.getData()
        },  
        methods:{
            getData() {
                console.log('调佣了吗')
                pageCompanyList({current:this.currentPage,inforId:this.pid,size:12,content:this.val}).then(res => {
                    if(res.code == 200) {
                        this.list = res.data.list;
                        this.count = res.data.count;

                    }
                })
            },
            isnum(e) {
                console.log('e',e)
            },  
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getData();
            },
            handleSizeChange(val) {
                this.currentPage = val;
                this.getData();
            },
        }
    }
</script>

<style lang="less" scoped>

</style>