<template>
    <div>
        <Header></Header>
        <div class="content">
            <headline title="研究会介绍"></headline>
            <div class="mt20 mb20">
                <div class="fw700 " style="font-size: 18px;line-height: 1.5;">{{ obj.introduct || '' }}</div>
                <!-- <div class="" v-for="(item,index) in obj.moduleList" :key="index">
                    <p class="fw700 flex_ac content-title">
                        {{item.title}}
                    </p>
                    <div class="" v-html="tran(item.text)"></div>
                </div> -->
                <div class="mentor-intro" v-for="(item,index) in obj.moduleList" :key="index">
                    <h3 class="section-title">{{item.title}}</h3>
                    <div class="section-content" v-html="tran(item.text)"></div>
                </div>
            </div>
            <headline title="研究会动态"></headline>
            <div class="mt50"></div>
            <dynamicList :list="list"></dynamicList>
            <!-- <nodata v-if="list.length == 0"></nodata> -->
            <div class="spczjz mt20 mb20">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="12"
                layout="prev, pager, next"
                :total="count">
                </el-pagination>
            </div>
        </div>
        
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import {pageCompanyList,aboutInfo} from "@/apiUrl/index.js";
    import dynamicList from '@/components/dynamicList.vue';
    export default {
        components:{
            dynamicList
        },
        data() {
            return {
                list:[],
                currentPage:1,
                count:0,
                obj:{}
            }
        },
        created() {
            this.getData();
            this.getList();
        },  
        methods:{
            getList() {
                pageCompanyList({current:this.currentPage,inforId:'',recommendType:1,size:10}).then(res => {
                    if(res.code == 200) {
                        this.list = res.data.list;
                        this.count = res.data.count
                    }
                })
            },
            getData() {
                aboutInfo({}).then(res => {
                    if(res.code == 200) {
                        this.obj = res.data
                    }
                })
            },
            tran(about_us) {
					if (!about_us) return ''
					about_us = about_us.replace(/<img src="/g,
							'<img style="max-width:100%;height:auto;display:block;margin-bottom:10px;border-radius: 4px;" src="'
							)
						.replace(/<li/g, '<li style="overflow: hidden;"');
					return about_us
				},
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList()
            },
            handleSizeChange(val) {
                this.currentPage = val;
                this.getList()
            },
        }
    }
</script>

<style lang="less" scoped>
.flex_ac {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-title {
    margin-top: 23px;
    margin-bottom: 23px;
    font-size: 18px;
    color: rgb(255, 0, 0);
}
.mentor-intro {
    // width: 800px;
    margin-top: 40px;
}
.section-title {
    font-size: 24px;
    letter-spacing: 1.4px;
}
.section-content {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
    color: #333;
    white-space: pre-line;
    word-break: break-all;
    text-align: justify;
}
.mentor-intro .section-content {
    margin-top: 24px;
}
</style>