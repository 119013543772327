<template>
    <div>
        <Header></Header>
        <banner></banner>
        <div class="content content_detail">
            <span class="Subtitle Title">{{ dynamic.content || '' }}</span>
            <br><br>
            <div class="Subtitle">
                <!-- <span class=" mr15">作者：admin</span> -->
                <span class=" mr15">来源：{{config.miniApps || ''}}</span>
                <span class="">浏览量：{{dynamic.readNum > 10000 ? changeUnits(dynamic.readNum) : dynamic.readNum || 0}}</span>
            </div>
            <br><br>
            <span class="Subtitle mr15 color666">发布于：{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:dynamic.updateDate}) }}</span>
            <div class="info" v-html="dynamic.contentDetail"></div>
            <div style="border-bottom: 1px solid rgb(204, 204, 204);margin: 0px auto;width: 100%;margin-top: 50px;margin-bottom: 50px;"></div>
        </div>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import { dynamicDetails } from "@/apiUrl/index.js";
    import { mapGetters } from "vuex"
    export default {
        computed: {
		  ...mapGetters(["userInfo", "allUserInfo","base_bgColor","config","share_text"]),
		},
        data() {
            return {
                did:'',
                dynamic:{}
            }
        },
        created() {
            let {id} = this.$route.query;
            this.did = id;
            this.getData()
        },
        methods:{
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            getData() {
                dynamicDetails({did:this.did}).then(e => {
                    if(e.code==200){
						var dynamic = e.data.dynamic;
						dynamic.urls = this.spmeth(dynamic.urls);
						this.dynamic = dynamic;
					}
                })
            },
            spmeth:function(e){
				var a = e.split(",")
				return a
			},
        }
    }
</script>

<style lang="less" scoped>
.content_detail {
    padding-top: 100px;
    // text-align: center;
    // width: 100%;
}
.Subtitle {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Title {
    text-align: center;
    font-size: 18px;
}
.info {
    padding: 50px;
}
</style>