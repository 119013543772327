<template>
    <div class="footer2">
        <div class="f_content">
            <!-- <div class="c_desc"><span class="desc">好实再集团提供平台技术支持</span></div> -->
            <div class="c_top"><!-- 联系我们 -->
                <div class="t_item" style="flex:1;display: flex;align-items: center;justify-content: center;">
                    <!-- <h1 class="i_title">联系我们</h1> -->
                    <!-- <div class="i_box m-t_30">
                        <div class="left">
                            <span class="item title">联系方式</span>
                            <span class="item m-t_10">电话: 010-83328775</span>
                            <span class="item">邮编 : 100071</span>
                            <span class="item">邮箱 : cnarms@163.com</span>
                        </div>
                        <div class="right m-l_130">
                            <span class="item title">公司地址</span>
                            <span class="item m-t_10">北京市丰台区丽泽商务区南区凤凰嘴街3号中华保险大厦</span>
                        </div>
                    </div> -->
                    <div class="" style="font-size: 16px;">
                        <p style="text-align: center;">地址：北京市丰台区丽泽商务区南区凤凰嘴街3号中华保险大厦</p>
                        <p class="mt20" style="text-align: center;"><span class="mr15">电话：010-83328775</span> <span class="mr15">邮编: 100071</span> <span class="mr15">传真：010-83328776</span> <span class="mr15">邮箱: cnarms@163.com</span></p>
                        <p  class="mt20"  style="text-align: center;"><span class="mr15">京公安网备案11010502040958</span> <span>中国农业风险管理研究会版权所有ICP证</span></p>
                    </div>
                </div><!-- 关注我们 -->
                <div class="t_item">
                    <!-- <h3 class="i_title">关注我们</h3> -->
                    <div class="i_codes ">
                        <div class="c_box">
                            <img class="lazyload code" alt="中国农业风险管理研究会" src="../static/images/qr_code4.png" style="display: block;">
                            <!-- <span class="desc m-t_10">中国农业风险管理研究会</span> -->
                        </div>
                        <div class="c_box m-l_54"><img class="lazyload code" alt="特色名片" src="../static/images/weibo_Code.png" >
                            <!-- <span class="desc m-t_10">源头特色真品</span> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="c_line m-t_30"></div>
            <div class="c_record m-t_30">
                <a class="r_item" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备14099383号-4 Copyright 2008-2019 深圳市好实再科技有限公司 All Rights Reserved.</a>
                <div class="r_licence">
                    <a class="item" href="https://qdz.hsz88.com/pdf/pc_cert/1.pdf" target="_blank">增值电信业务经营许可证(EDI)：粤B2-20150376</a>
                    <a class="item" href="https://qdz.hsz88.com/pdf/pc_cert/3.png" target="_blank"> | 食品经营许可证</a>
                    <a class="item" href="https://qdz.hsz88.com/pdf/pc_cert/2.png" target="_blank"> | 开户许可证</a>
                    <a class="item" href="https://qdz.hsz88.com/pdf/pc_cert/4.png" target="_blank"> | 营业执照</a>
                    <a href="https://szcert.ebs.org.cn/427d1d44-3824-497a-9afc-a4b8a9ddf246" target="_blank">
                    <img class="lazyload pic m-l_10" alt="工商网监电子标识" src="../static/images/footer-licence.png" style="display: inline;"></a>
                    </div>
                </div> -->
            </div>
    </div>
</template>

<script>
    export default {
        props:{
			type:{
				type:String,
				// default:'1'
			},
		},
        data() {
            return {
                height:0
            }
        },
        created(){
            this.height = document.documentElement.clientHeight;
        },
        methods:{
            link_beian() {
                window.open('https://beian.miit.gov.cn/','_blank')
            }
        }
    }
</script>

<style lang="less">
.footer2 {
    width: 100%;
    padding: 50px 0;
    margin-top: 100px;
    background: #F8F8F8;
}
.m-l_130 {
    margin-left: 130px;
}
.m-l_54 {
    margin-left: 54px;
}
.m-l_10 {
    margin-left: 10px;
}
.f_content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.f_content > .c_desc {
    display: flex;
    justify-content: center;
}
.f_content > .c_desc > .desc {
    font-size: 20px;
    font-weight: bold;
    color: #2F3232;
    line-height: 29px;
}
.f_content > .c_top {
    display: flex;
    justify-content: space-between;
}
.f_content > .c_top > .t_item > .i_title {
    font-size: 26px;
    font-weight: bold;
    color: #2F3232;
    line-height: 29px;
}
.f_content > .c_top > .t_item > .i_box {
    display: flex;
}
.f_content > .c_top > .t_item > .i_box > .left, .f_content > .c_top > .t_item > .i_box > .right {
    display: flex;
    flex-direction: column;
}
.f_content > .c_top > .t_item > .i_box > .left > .item, .f_content > .c_top > .t_item > .i_box > .right > .item {
    font-size: 15px;
    font-weight: bold;
    color: #2F3232;
    line-height: 30px;
}
.m-t_10 {
    margin-top: 10px;
}
.f_content > .c_top > .t_item > .i_codes {
    display: flex;
}
.m-t_22 {
    margin-top: 22px;
}
.f_content > .c_top > .t_item > .i_codes > .c_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.f_content > .c_top > .t_item > .i_codes > .c_box > .code {
    width: 137.5px;
    height: 137.5px;
}
.f_content > .c_line {
    width: 100%;
    height: 1px;
    background: #E3E3E3;
}
.m-t_30 {
    margin-top: 30px;
}
.f_content > .c_record {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.m-t_30 {
    margin-top: 30px;
}
.f_content > .c_record > .r_item {
    font-size: 12px;
    font-weight: bold;
    color: #2F3232;
    line-height: 30px;
}
.f_content > .c_record > .r_licence {
    display: flex;
    align-items: center;
}
.f_content > .c_record > .r_licence > .item {
    font-size: 12px;
    font-weight: bold;
    color: #2F3232;
    line-height: 30px;
}
.f_content > .c_record > .r_licence .pic {
    width: 64px;
    height: 26px;
}

</style>