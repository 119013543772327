/**
* http 配置
*/
// 引入 axios 以及 element ui 中的 loading 和 message 组件
import axios from 'axios'
import { Loading, Message } from 'element-ui'
import router from "../router";
import store from '../store/index'
// 超时时间
axios.defaults.timeout = 30000
// http 请求拦截器
var loadinginstace
axios.interceptors.request.use(config => {
    if(config.loading){
        loadinginstace = Loading.service({ fullscreen: true })
    }
   
    if(config.data&&config.data.form=='form'){//form表单方式提交
        config.headers["Content-Type"] = "application/x-www-form-urlencoded"
        delete config.data.form
        const params = new URLSearchParams();
        for(var i in config.data){
            params.append(i,config.data[i])
        }
        config.data = params;
    }
    if(localStorage.getItem("token")){//token
        if(localStorage.getItem("token") != 'null') {
            config.headers['token'] = localStorage.getItem("token") || '';
        }
    }
    config.headers['tenantId'] = store.state.tenantId;
    return config
}, error => {
    loadinginstace?loadinginstace.close():'';
    Message.error({
        message: '加载超时'
    })
})
// http 响应拦截器
axios.interceptors.response.use(data => {// 响应成功关闭 loading
    let code = data.data.code
    loadinginstace?loadinginstace.close():'';
    if(data.data.code==507 || data.data.code==508 || data.data.code==509){
        store.commit('setlogin_show',true);
        store.commit('settoken','');
        store.commit('setuserInfo',{});
        if(data.data.code==507 || data.data.code==508) {
            Message.error({
                message: data.data.message
            })
        } else if(data.data.code==509) {
            // Message.error({
            //     message: '请先登录'
            // })
        }
        
        router.replace({path:'/'});
        return
    }
    return data
}, error => {
    loadinginstace?loadinginstace.close():'';
    if(error.response.data.code==507 || error.response.data.code==508 || error.response.data.code==509){
        store.commit('setlogin_show',true);
        store.commit('settoken','');
        store.commit('setuserInfo',{});
        Message.error({
            message: data.data.message
        })
        return
    }
    if(error.response.data.code!=400 && error.response.data.code!=401){
        Message.error({
            message: '加载失败,请刷新重试!'
        })
        return
    }
    return error.response.data
})
export default axios