<template>
    <div>
        <div class="banner_img" @click="o-click">
            <img src="../static/images/head_bg2.gif" alt="">
        </div>
    </div>
</template>

<script>
import { sourc, sourc2 } from "@/apiUrl/index.js";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config", "login_show", "token"]),
    },
    props: {
        img_url: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sourc,
            sourc2,
        };
    },
    created() {},
    methods: {},
};
</script>

<style lang="less" scoped>
    .banner_img{
        width: 100%;
        // min-width: 1200px;
        // height: 500px;
        img{
            object-fit:'contain';
            width: 100%;
        }
    }
</style>
