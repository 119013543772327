<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="partyMassess-list">
                <div class="partyMassess-content">
                    <div class="content-left" >
                        <el-carousel height="340px" trigger="click">
                            <el-carousel-item v-for="(item,index) in advertList" :key="index" >
                                <img :src="item.advPosPic|| ''" alt="" class="banner_img" @click="link_adv(item.type,item.typeId)">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="content-right" >
                        <div class="content-title">
                            <div class="title " :class="tab_num == 1 ? 'content-title-active' : ''" @click="click_tab(1)">
                                新闻资讯
                                <div class="trangle" v-show="tab_num == 1"></div>
                            </div> 
                            <div class="title" :class="tab_num == 2 ? 'content-title-active' : ''" @click="click_tab(2)">
                                通知公告
                                <div class="trangle" v-show="tab_num == 2"></div>
                            </div>
                        </div>
                        <div class="content-right-item" v-for="(item,index) in pubList1" :key="index" v-if="tab_num == 1&&index <= 8" @click="link_pub_detail(item.id)">
                            <div class="circle"></div> 
                            <div class="content-right-item-title">{{ item.content || '' }}</div>
                            <div class="content-right-item-time">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                        </div>
                        <div class="content-right-item" v-for="(item,index) in pubList3" :key="index" v-if="tab_num == 2&&index <= 8" @click="link_pub_detail(item.id)">
                            <div class="circle"></div> 
                            <div class="content-right-item-title">{{ item.content || '' }}</div>
                            <div class="content-right-item-time">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="nav_list">
                <div class="nav_li">
                    <div class="btn-tops">
                        <div class="">
                           <h1 class="adh">研讨活动</h1>
                        </div>
                        <span class="span_right" @click="link_move(1)">更多></span>
                    </div>
                    <div class="content-right-item content-right-item2" v-for="(item,index) in pubList2" :key="index" v-if="index <= 8" @click="link_pub_detail(item.id)">
                        <div class="circle"></div> 
                        <div class="content-right-item-title">{{ item.content || '' }}</div>
                        <div class="content-right-item-time">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                    </div>
                </div>
                <div class="nav_li">
                    <div class="btn-tops">
                        <div class="">
                           <h1 class="adh">专家观点</h1>
                        </div>
                        <span class="span_right" @click="link_move(2)">更多></span>
                    </div>
                    <div class="content-right-item content-right-item2" v-for="(item,index) in pubList4" :key="index" v-if="index <= 8" @click="link_pub_detail(item.id)">
                        <div class="circle"></div> 
                        <div class="content-right-item-title">{{ item.content || '' }}</div>
                        <div class="content-right-item-time">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                    </div>
                </div>
                <div class="nav_li">
                    <div class="btn-tops">
                        <div class="">
                           <h1 class="adh">发展报告</h1>
                        </div>
                        <span class="span_right" @click="link_move(3)">更多></span>
                    </div>
                    <div class="content-right-item content-right-item2" v-for="(item,index) in pubList5" :key="index" v-if="index <= 8" @click="link_pub_detail(item.id)">
                        <div class="circle"></div> 
                        <div class="content-right-item-title">{{ item.content || '' }}</div>
                        <div class="content-right-item-time">{{ $TimeFormat({fmt:'yyyy-MM-dd hh:mm',date:item.createDate}) }}</div>
                    </div>
                </div>
                <!-- <div class="nav_li">
                    <div class="btn-tops">
                        <div class="">
                           <h1 class="adh">发展报告</h1>
                        </div>
                        <span class="span_right" @click="link_move(3)">更多></span>
                    </div>
                    <div class="content-right-item3" v-for="(item,index) in pubList5" :key="index" @click="link_mem_detail(item.id)" v-if="index < 4">
                        <img :src="item.headPortraitUrl" class="item_header" alt="">
                        <div >
                            <div class="fw700 item-title">{{ item.name }}</div>
                            <div class="mt10 chaochu color999" style="flex:1;">{{ item.jobTitle }}</div>
                        </div>
                    </div>
                    
                </div> -->
                
                
            </div>
        </div>
        <banner></banner>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {jsonIndex,pageCompanyList, aboutInfo,sourc,sourc2,homeIndex,selectByIdBase2,getByIdBase } from "@/apiUrl/index.js";
    import videoList from '@/components/videoList'
    export default {
        components:{
            videoList,
        },
        computed: {
            ...mapGetters(["token"]),
        },
        data() {
            return {
                sourc,sourc2,
                goods: [],
				video_list:[], // 精选推荐
				id: '',
				version: true, // true审核版本  false不是审核版本
				project_name: this.$store.state.project_name,
				list:[],
				projectList:[],
				memberPhotoAlbum:{},
				teacherMenuList:[],
				teacherMenuOK:false,
				teacherdots:false,
				memberPhotoAlbumList:[],
				teacherList:[],
				homePageStatus:1,
				projec:[],
				serviceVideoPhotoUrl:'',
				serviceVideoUrl:'',
				advertList:[], // 广告位
				activityList:[], // 活动列表
				tenantSet:{}, // 租户信息
				Teachertype:{}, // 导师类型
				VideoType:{}, // 视频类型
				projectType:{}, // 项目类型
				activityType:{}, // 活动类型
				publicizeType:{}, // 资讯类型
				aboutUsType:{}, // 简介类型
				topicType:'',
				topicSolidList:[],// 固定话题列表
				InformationList:[], // 资讯数据列表
				aboutInfo:{},
                aboutObj:{},
				showInfo:false,
                tab_num:1,
                pubList1:[], // 新闻资讯
                pubList2:[], // 研讨活动
                pubList3:[], // 通知公告
                pubList4:[], // 入会指南
                pubList5:[], // 发展报告
            };
        },
        created() {
            /* this.$store.commit('setMenuList',[]); */
            this.getdata_v3();
            this.getAbout();
            this.getlist();
            
        },
        methods: {
            link_mem_detail(id) {
                this.$links('/member_detail?id=' + id)
            },
            link_move(type) {
                if(type == 1) {
                    this.$links('/information_list?pid=c241c6d4c88543b5a30cd5174b26016f&name=研讨活动')
                } else if(type == 2) {
                    this.$links('/information_list?pid=a05e9858a45c4cfa841c78b1c1428913&name=专家观点')
                } else if(type == 3) {
                    this.$links('/information_list?pid=f5ef9a7bb18249e2b878ce84201153da&name=发展报告')
                }
            },
            link_pub_detail(id) {
                this.$links('/publicDetail?id=' + id)
            },
            click_tab(num) {
                this.tab_num = num
            },  
            tran(about_us) {
					if (!about_us) return ''
					about_us = about_us.replace(/<img src="/g,
                        '<img style="margin:0 auto;width:300px;height:auto;display:block;margin-bottom:10px;border-radius: 4px;" src="'
                        )
                    .replace(/<li/g, '<li style="overflow: hidden;"');
					return about_us
				},
            getAbout() {
                aboutInfo().then(res => {
                    if(res.code == 200) {
                        this.aboutObj = res.data
                    }
                    
                })
            },
            links_news(id,type) {
                if(type == 1) {
                    this.$links('/news_detail?id=' + id)
                } else if(type == 2) {
                    this.$links('/v_play?id=' + id)
                } else if(type == 3) {
                    this.$links('/news_detail2?id=' + id)
                }
            },
            link_adv(type,id) {
				console.log('id',id)
				if(type == 'teacher') { // 导师类型
					// uni.navigateTo({ url: '/pagesB/home/tutor_detail?id=' + id})
                    this.$links('/member_detail?id=' + id)
				} else if(type == 'video') { // 视频类型
					selectByIdBase2({id}).then(res => {
							if(res.code == 200) {
								let obj = res.data;
								if(obj.type==1){
									this.$link('/video_detail?id=' + id + '&mode=video&timestamp=' + new Date())
								}else if(obj.type==2){
									if(obj.vipSet==2){
										this.$link('/video_detail2?id=' + id + '&mode=video&timestamp=' + new Date())
									}else{
										this.$link('/video_detail1?id=' + id + '&mode=video&timestamp=' + new Date())
									}
								}
							}
					})
				} else if(type == 'project') { // 商机类型
					// uni.navigateTo({ url: '/pagesA/projectDetail/projectDetail?id=' + id})
				} else if(type == 'activity') { // 活动类型
					// uni.navigateTo({ url: '/pagesB/activity/activityDetail?id=' + id})
				} else if(type == 'publicize') { // 活动类型
					if(id) {
						getByIdBase({id}).then(res => {
							if(res.code == 200) {
								let data = res.data;
								if(data.fileType == 1) {
                                    // this.$links('/news_detail?id=' + id)
                                    this.$links('/publicDetail?id=' + id)
								} else if(data.fileType == 2) {
									this.$links('/v_play?id=' + id)
								} else if(data.fileType == 3) {
									// this.$links('/news_detail2?id=' + id)
                                    this.$links('/publicDetail?id=' + id)
								}
							}
							
						})
					} else {
						this.$msg('跳转失败！')
					}
				} 
			},
            link_page(url,name,id,type){
				let urls = '';
				if(type == 'video') { // 视频类型
					urls = '/screen'
				} else if(type == 'teacher') { // 导师类型
					// urls = '/pagesB/home/tutor_list'
				} else if(type == 'publicize') { // 企业宣传
					urls = '/news_list'
				} else if(type == 'project') { // 商机类型
					// urls = '/pages/home/index/project'
				} else if(type == 'activity') { // 活动类型
					// urls = '/pagesB/activity/list'
				} else if(type == 'about_us') { // 企业简介
					// urls = '/pagesA/about_us/index'
					id = '';
				}else{
                    this.$link(url)
					return
				}
				this.$links(urls+'?id=' + id + '&icon_name=' + name)
			},
            getdata_v3(e) {
				var el = this;
                //  homeIndex
				jsonIndex({teacherTopic:1}).then((res) => {
					if(res.code==200){
						
						if(res.data.status) { // 租户可用
							this.advertList = res.data.advertList || []; // 广告
							if((this.$store.state.tenantId=='3cfb027eb4634dfab764403a4cfdf01a' || this.$store.state.tenantId=='271d5662d57148a5812297c5cc50b7d3') &&!this.version){
								this.teacherMenuList =[{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=0',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/01.png',
									name: "个股",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=1',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/02.png',
									name: "指数",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=2',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/03.png',
									name: "ETF",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/strategy',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/04.png',
									name: "每日策略",
								}]
							}else{
								this.teacherMenuList =[]
							}
							this.teacherMenuList = res.data.classList || []; // 分类
							
							this.teacherMenuOK =  this.teacherMenuList.length>5; 
							this.teacherdots =  this.teacherMenuList.length>10; 
							this.video_list = res.data.pushVideoList || []; // 推荐视频列表
							this.tenantSet = res.data.tenantSet; // 租户信息
							this.teacherList = res.data.teacherList || []; // 名师推荐
							let list = res.data.projectList || []; // 商机推荐
							list.forEach(item => {
								item.customLabel1 = item.customLabel ?  item.customLabel.split(',') : []
							})
							this.projectList = list;
							// this.project_name = res.data.tenantSet.miniApps;
							this.activityList = res.data.activityList || []; // 活动推荐
							let InformationList = res.data.publicizeList || []; //资讯推荐
							InformationList.forEach((v,i)=>{1
								if(v.fileType==1&&v.urls){
									InformationList[i].urls = v.urls.split(',')
								}
							})
							this.InformationList = InformationList;
                            this.$store.commit('setMenuList',this.teacherMenuList);
							this.$store.commit('setTenant',this.tenantSet);
							// // this.$store.commit('setShare_text',this.tenantSet.shareDescription || '');
							// this.$store.commit('setProject_name',this.tenantSet.miniApps || '');
							// this.$store.commit('setApp_share_img',this.tenantSet.sharePhoto || '');
							// this.$store.commit('setApplicationTypeList',res.data.applicationTypeList || []);
							// this.$store.commit('setTopicType',res.data.topicType || '');
							// this.topicType = res.data.topicType || '';
							// if(this.topicType == 'solid') {
							// 	this.getTopicSolid();
							// } 
							// if(res.data.applicationTypeList.length ) {
							// 	res.data.applicationTypeList.forEach((item) => {
							// 		if(item.typeMark == 'teacher') {
							// 			this.Teachertype = item
							// 		} else if(item.typeMark == 'video') {
							// 			this.VideoType = item
							// 		} else if(item.typeMark == 'project') {
							// 			this.projectType = item
							// 		} else if(item.typeMark == 'activity') {
							// 			this.activityType = item
							// 		}else if(item.typeMark == 'publicize') {
							// 			this.publicizeType = item 
							// 		} else if(item.typeMark == 'about_us') {
							// 			this.aboutUsType = item 
										
							// 		} 

							// 	})
							// }
							// setTimeout(() => {
							// 	this.showInfo = true;
							// },200)
							
						}
					}else{
						this.$msg(res.message);
					}
				})
			},
            // 详情
            link_detail() {
                this.$links('/video_detail');
            },
            getlist() {
                /* 新闻资讯 */
                pageCompanyList({current:1,size:9,inforId:'bd2cfe15dac4410da63ea64b4dccb3ec'}).then(res => {
                    if(res.code == 200) {
                        this.pubList1 = res.data.list
                    }
                })
                /* 研讨活动 */
                pageCompanyList({current:1,size:9,inforId:'c241c6d4c88543b5a30cd5174b26016f'}).then(res => {
                    if(res.code == 200) {
                        this.pubList2 = res.data.list
                        console.log('pubList2',this.pubList2)
                    }
                })
                /* 通知公告 */
                pageCompanyList({current:1,size:9,inforId:'ed55cdda20b143e598fed31f8d7857cc'}).then(res => {
                    if(res.code == 200) {
                        this.pubList3 = res.data.list
                    }
                })
                /* 入会指南 */
                pageCompanyList({current:1,size:9,inforId:'a05e9858a45c4cfa841c78b1c1428913'}).then(res => {
                    if(res.code == 200) {
                        this.pubList4 = res.data.list
                    }
                })
                 /* 发展报告 */
                 pageCompanyList({current:1,size:9,inforId:'f5ef9a7bb18249e2b878ce84201153da'}).then(res => {
                    if(res.code == 200) {
                        this.pubList5 = res.data.list
                    }
                })
            }
        },
    };
</script>
<style lang="less" scoped>
.partyMassess-list {
    padding: 26px;
    border-radius: 8px;
    border: 1px solid #f4f4f4;
    margin-top: 20px;
}
.partyMassess-content {
    display: flex;
}
.content-left {
    width: 740px;
    height: 340px;
}
.content-right {
    padding: 0 20px;
    width: 40%;
}
.content-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
        width: 49%;
        padding: 14px 0;
        text-align: center;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        background-color: #f7f7f7;
    }
    .content-title-active {
        background-color: #008449 !important;
        color: #fff !important;
        position: relative;
    }
    .trangle {
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #008449;
        position: absolute;
        bottom: -20px;
    }
}
.content-right-item {
    padding: 5px 0;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    cursor: pointer;
    .circle {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #018445;
        margin-right: 10px;
    }
    .content-right-item-title {
        color: #666;
        font-size: 14px;
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.content-right-item2 {
    padding: 10px 0;
}
.content-right-item:last-child {
    border-bottom: 0;
}
.content-right-item:hover,.content-right-item-title:hover {
    color: #008449 !important;
}
.nav_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
.nav_li {
    width: 380px;
    height: 450px;
    // margin-right: 20px;
    // overflow: hidden;
    margin-bottom: 10px;
}
.btn-tops {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 20px;
}
.btn-tops .adh {
    width: 158px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    background-color: #008449;
    color: #fff;
}
.btn-tops h1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 0px;
    float: left;
    padding: 23px 15px;
    display: block;
    cursor: pointer;
}
.span_right {
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 52px;
    cursor: pointer;
}
.content-right-item3 {
    padding: 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .item_header {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
    }
    .item-title {
        font-size: 18px;
    }
}
.content-right-item3:hover {
    box-shadow: 0 0 6px 1px rgb(109 179 63 / 27%);
    border-radius: 10px;
}
</style>
  