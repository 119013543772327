<template>
    <div>
        <div class="tab_list">
            <div @click="isnum(item,index)" :class="num == index ? 'tab-list-active' : ''" v-for="(item,index) in list" :key="index">{{ item.name }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            list:{
                type:Array,
                default:() => []
            }
        },
        data() {
            return {
                num:0,
                list:[]
            }
        },
        methods:{
            isnum(item,index) {
                this.num = index
                this.$emit('click',item)
            }
        }
    }
</script>

<style lang="less" scoped>
.tab_list {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab_list div {
    min-width: 188px;
    text-align: center;
    padding: 12px 0;
    border-radius: 7px;
    margin: 20px 10px;
    color: #3a3a3a;
    font-size: 18px;
    cursor: pointer;
}
.tab-list-active {
    background-color: rgba(109,179,63,.11);
}
</style>