<template>
    <div>
        <div class="member_list">
            <div class="member_item" v-for="item in list" :key="item.id" @click="link_detail(item.id)">
                <div>
                    <img class="member_img" :src="item.headPortraitUrl" alt="">
                </div>
                <div class="member_name">{{ item.name || '' }}</div>
                <div class="member_snapshot caochu">
                    {{ item.jobTitle }}
                </div>
                <div class="member_achieve caochu">
                    {{ item.majorAchievement || '' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            list:{
                type:Array,
                default:() => []
            }
        },
        methods:{
            link_detail(id) {
                this.$links('/member_detail?id=' + id)
            }
        }
    }
</script>

<style lang="less" scoped>
.member_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.member_item {
    padding: 10px;
    width: 345px;
    height: 286px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(1,132,69,.24);
    margin: 10px 26px;
    cursor: pointer;
}
.member_img {
    width: 108px;
    height: 108px;
    border-radius: 108px;
    margin-bottom: 10px;
}
.member_name {
    color: #202020;
    font-size: 18px;
}
.member_snapshot {
    font-size: 16px;
    color: #b1b1b1;
}
.member_achieve {
    font-size: 16px;
    color: #018445;
    margin: 4px 10px 0;
}
</style>