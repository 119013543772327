<template>
    <div style="position: sticky;top: 0;z-index: 999;">
        <div class="header_content" v-if="isOK">
            <img src="../static/images/head_bg.png" alt="" class="head-bg" />
            <div class="main-top">
                <div class="main-top-logo" @click="$link('/')">
                    <!-- <img class="main-logo" :src="config && config.logoPhoto ? config.logoPhoto : ''" alt="">
                    <span class="main-logo-text">{{ config && config.slogan ? config.slogan : '' }}</span> -->
                    <!-- <img class="logo_img" :src="config && config.logoPhoto ? config.logoPhoto : ''" alt=""> -->
                    <img class="logo_img" src="../static/images/logo.png" alt="">
                </div>
                <div class="main-top-list">
                    <div class="search-box">
                        <div class="seach-icon" style="margin-right: 10px">
                            <div class="iconfont icon-sousuo1" style="font-size: 30px;color: var(--color);"></div>
                        </div>
                        <input type="text" v-model="val" placeholder="输入搜索内容" />
                        <div class="search-btn" style="cursor: pointer" @click="sharch">
                            搜索
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-body">
                <div class="main-body-nav">
                    <ul>
                        <li class="active" style="cursor: pointer"  @click="$link('/')">
                            <span> 首页</span>
                        </li>
                        <li class="" style="cursor: pointer" v-for="(item,index) in MenuList" :key="index" @mouseenter="handleMouseEnter(item)">
                            <span>{{ item.name }}</span>
                            <div class="main-sublevel">
                                <div v-for="(item2,index2) in item.children" :key="index2" @click="clicks(item2,item.atMark)">{{ item2.name }}</div>
                            </div>
                        </li>
                        <!-- <li class="" style="cursor: pointer">
                            <span> 关于研究会</span>
                            <div class="main-sublevel">
                                <div @click="$link('/about/about_us')">简介</div>
                                <div>章程</div>
                                <div>研究会领导</div>
                                <div>历史沿革</div>
                                <div>公益项目</div>
                                <div>联系我们</div>
                            </div>
                        </li>
                        <li class="" style="cursor: pointer">
                            <span> 新闻中心</span>
                            <div class="main-sublevel">
                                <div @click="$link('/information_list')">新闻资讯</div>
                                <div>研究会要闻</div>
                                <div>通知公告</div>
                                <div>行业政策</div>
                            </div>
                        </li>
                        <li class="" style="cursor: pointer">
                            <span> 组织机构</span>
                            <div class="main-sublevel">
                                <div>管理机构</div>
                                <div>分支机构</div>
                            </div>
                        </li>
                        <li class="" style="cursor: pointer">
                            <span> 学术研究</span>
                            <div class="main-sublevel">
                                <div>研讨活动</div>
                                <div>研究成功</div>
                                <div>出版物</div>
                                <div>学者专栏</div>
                            </div>
                        </li>
                        <li class="" style="cursor: pointer">
                            <span> 智库建设</span>
                            <div class="main-sublevel">
                                <div  @click="$link('/institutionList')">智库专家</div>
                                <div>专家观点</div>
                                <div>数据库</div>
                            </div>
                        </li>
                        <li class="" style="cursor: pointer">
                            <span> 会员之窗</span>
                            <div class="main-sublevel">
                                <div>单位会员</div>
                                <div>个人会员</div>
                                <div @click="$link('/consult')">入会指南</div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sourc, sourc2 , selectByPid} from "@/apiUrl/index.js";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config", "login_show", "token","MenuList"]),
    },
    components: {},
    props: {
        isOK: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            sourc,
            sourc2,
            list:[],
            val:'',
        };
    },
    watch: {
        MenuList() {

        }
    },
    created() {
        // this.list = this.MenuList;
    },
    methods: {
        sharch() {
            this.$links('/sharch_list?val=' + this.val)
        },  
        clicks(obj,atMark) {
            if(atMark == 'publicize') { // 如果是资讯类型
                if(obj.id == '7df3d0cddb584160bea365c3903f9b78' || obj.id == '5b2779d34e99433796232c71851b93d8') { // 关于研究院-简介 || 关于研究院-联系我们
                    // this.$link('/about/about_us')
                    this.$link('/information_list?pid=' + obj.id + '&name=' + obj.name);
                    return
                }
                if(obj.id == '1e892e31e5fc4001ac677ee68a66b292') { // 智库专家
                    // this.$link('/institutionList?pid=02109b34f8a74a8e930b82603a4ee580&name=' + obj.name)
                    this.$link('/information_list?pid=' + obj.id + '&name=' + obj.name);
                    return
                }
                this.$link('/information_list?pid=' + obj.id + '&name=' + obj.name);
            }
            if(atMark == 'teacher') { // 导师类型
                if(obj.id == '30c2a217e3ee4ab8aae1028c730b2d0b') { // 会员之窗-单位会员
                    this.$link('/institutionList?pid=e0c0008fac224e46ab731c6da1633c1d&name=' + obj.name)
                }
                if(obj.id == 'c277efe5618147089aefb021bacd9506') { // 会员之窗-个人会员
                    this.$link('/consult')
                }
                if(obj.id == 'da25ebf82c904fbb867966ff3d233ec1') { // 会员之窗-入会指南
                    this.$link('/information_list?pid=ee5c12be8c0c4ec6b9469b22ab1158d7&name=' + obj.name);
                }
            }
        },
        handleMouseEnter(obj) {
            selectByPid({pid:obj.id}).then(res => {
                if(res.code == 200) {
                    this.$set(obj,'children',res.data);
                    this.$store.commit('setMenuList',this.MenuList);
                    // this.list = this.MenuList;
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.header_content {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    position: sticky;
    top: 0;
    z-index: 12;
    .head-bg {
        position: absolute;
        width: 100%;
        height: 210px;
        top: 0;
        left: 0;
        display: block;
    }
}
.main-top {
    height: 210px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.main-top-logo .logo_img {
    // height: 86px;
    width: 540px;
    height: 150px;
    overflow: hidden;
}
.main-top-logo {
    display: flex;
    align-items: center;
}
.main-logo-text {
    margin-left: 20px;
    font-size: 25px;
    font-weight: 600;
}
.main-logo {
    width: 70px;
    height: 70px;
}
.main-top-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
}
.search-box {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}
.seach-icon img {
    width: 20px;
    height: 20px;
}
.search-box input,
.search-box input:focus {
    border: 0;
    outline: 0;
    width: 260px;
}
.search-btn {
    width: 102px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background-color: var(--color);
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}


.main-body {
    height: 54px;
    margin-top: -15px;
    position: relative;
    background-color: var(--color);
}
.main-body-nav {
    width: 1200px;
    margin: auto;
}
.main-body-nav>ul>li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5.5%;
    height: 54px;
    line-height: 54px;
    text-align: left;
    color: #fff;
    font-size: 20px;
    position: relative;
    float: left;
    cursor: pointer;
}
.active {
    color: #fff!important;
}

.main-sublevel {
    width: 100%;
    height: auto;
    padding: 0;
    position: absolute;
    top: 54px;
    z-index: 999;
    display: none;
    color: #333 !important;
    // 
    font-size: 18px;
    // background-color: rgba(0,0,0,.7);
    background-color: #97BEC2;
}
.main-sublevel div {
    white-space: nowrap;
    font-size: 18px;
    border-bottom: 1px solid #fff;
}
.main-sublevel div:last-child {
    border-bottom: 0;
}
.main-body-nav>ul>li:hover .main-sublevel {
    color: #fff;
    display: inline-block;
    width: 150px;
    text-align: center;
}
.main-sublevel div:hover {
    color: #008449;
}
</style>
