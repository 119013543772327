<template>
    <div>
        <div class="about-title">
            <div class="about-text">{{ title }}</div>
            <div class="title-bottom">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '',
            },
        },
    }
</script>

<style lang="less" scoped>
.about-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 600px;
    margin: 0 auto;
    color: #333;
    font-size: 34px;
    padding: 30px 0;
    border-bottom: 1px solid #cfcfcf;
}
.about-text {
    color: #333;
    font-size: 34px;
}
.about-title .title-bottom{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
}
.about-title .title-bottom div:first-child {
    width: 88px;
    height: 4px;
    background-color: #7dbef7;
}
.about-title .title-bottom div:nth-child(2) {
    width: 88px;
    height: 4px;
    background-color: var(--color);
}
</style>