import { render, staticRenderFns } from "./information_list.vue?vue&type=template&id=cda8645e&scoped=true&"
import script from "./information_list.vue?vue&type=script&lang=js&"
export * from "./information_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cda8645e",
  null
  
)

export default component.exports