<template>
  <div id="app">
      <keep-alive>
        <router-view  v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
      </keep-alive>
      <router-view  v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
      <el-backtop target="" bottom="130"></el-backtop>
  </div>
</template>
<script>
import {mapGetters } from 'vuex'
import {jsonIndex,homeIndex} from "@/apiUrl/index.js";
export default {
  computed: {
    ...mapGetters(['token'])
  },
  data () {
    return {
      teacherMenuList:[],
      tenantSet:[]
    }
  },
  watch: {
   
  },
  created(){
      // 初始化后  vue2-editor.esm.js  multiple  
      // setTimeout(()=>{
      //   this.verify();//验证token
      // },1000)
      this.$store.dispatch("getconfig");//获取系统配置
      this.$store.dispatch("getDict");//数据字典
      this.getdata_v3()
      // this.$store.dispatch("getvideoClass");//获取视频分类
      // if(this.token){
      //     this.$store.dispatch("getUserInfo");
      // }
     
  },
  methods:{
      // setheartbeat(){
      //     heartbeat().then((res)=>{

      //     })
      // },
      // verify(){
      //     verifyToken().then((res)=>{
      //         if(!res){
      //             this.$store.commit('settoken','');
      //             this.$store.commit('setuserInfo',{});
      //             this.$store.commit('sethistryList',[]);
      //         }else{
      //             this.$store.dispatch("getUserInfo");
      //         }
      //     })
      // }
      getdata_v3(e) {
				var el = this;
        //  homeIndex
				jsonIndex({teacherTopic:1}).then((res) => {
					if(res.code==200){
						
						if(res.data.status) { // 租户可用
							this.teacherMenuList = res.data.classList || []; // 分类
							
							this.teacherMenuOK =  this.teacherMenuList.length>5; 
							this.teacherdots =  this.teacherMenuList.length>10; 
							this.tenantSet = res.data.tenantSet; // 租户信息
              this.$store.commit('setMenuList',this.teacherMenuList);
							this.$store.commit('setTenant',this.tenantSet);

							setTimeout(() => {
								this.showInfo = true;
							},200)
							
						}
					}else{
						this.$msg(res.message);
					}
				})
			},
  }
}
</script>
<style >
</style>
