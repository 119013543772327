const getters = {
	// 用户信息
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  config: state => state.user.config,
  login_show: state => state.user.login_show,
  languageLabels: state => state.user.languageLabels,
  labels: state => state.user.labels,
  MonthIncomeArr: state => state.user.MonthIncomeArr,
  MenuList:state => state.user.MenuList,
  educationArr: state => state.user.educationArr,
}

export default getters
