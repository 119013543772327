<template>
    <div>
        <Header></Header>
        <div class="content">
            <headline :title="name"></headline>
            <div class="mt50 mb30">
                <memberList :list="list"></memberList>
                <nodata v-if="list.length == 0"></nodata>
            </div>
            <div class="spczjz mt20 mb20">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="12"
                layout="prev, pager, next"
                :total="count">
                </el-pagination>
            </div>
        </div>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import {  teacherPage} from "@/apiUrl/index.js";
    import memberList from '@/components/member_list.vue';
    export default {
        components:{
            memberList
        },
        data() {
            return {
                pid:'',
                name:'',
                list:[],
                currentPage:1,
                count:0,
            }
        },
        created() {
            let {name,pid} = this.$route.query;
            this.name = name;
            this.pid = pid;
            this.getData()
        },  
        methods:{
            getData() {
                teacherPage({current:this.currentPage,size:20,threeType:this.pid,teacherTopic:1}).then(res => {
                    if(res.code == 200) {
                        this.list = res.data.list
                    }
                })
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getData();
            },
            handleSizeChange(val) {
                this.currentPage = val;
                this.getData();
            },
        }
    }
</script>

<style lang="less" scoped>

</style>