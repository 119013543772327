import { render, staticRenderFns } from "./sharch_list.vue?vue&type=template&id=5fbed30c&scoped=true&"
import script from "./sharch_list.vue?vue&type=script&lang=js&"
export * from "./sharch_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbed30c",
  null
  
)

export default component.exports